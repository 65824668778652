import Vue from "vue"
import App from "./App.vue"
import router from "./router"

import vueCompositionApi from '@vue/composition-api'
Vue.use(vueCompositionApi)

import { firestorePlugin } from 'vuefire'
Vue.use(firestorePlugin)

Vue.config.productionTip = false

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import {app} from '@/firebase.js'

new Vue({
	router,
	render: (h) => h(App),
}).$mount("#app")
