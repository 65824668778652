<template>
	<main class="main" id="top">
        <NavBar></NavBar>

        <!-- ============================================-->
        <!-- <section> begin ============================-->
        <section class="py-0 overflow-hidden" id="banner">
            <div class="bg-holder overlay" style="background-position: center bottom"></div>

        </section>
        <!-- <section> close ============================-->
        <!-- ============================================-->

        <!-- ============================================-->
        <!-- <section> begin ============================-->
        <section class="py-0 overflow-hidden bg-light" id="banner">
            <div class="bg-holder overlay" style="background-position: center bottom"></div>
            <!--/.bg-holder-->

            <div class="container light">
                <div class="row flex-center pt-8 pt-lg-10 pb-lg-9 pb-xl-0">
                    <div class="col-md-11 col-lg-10 col-xl-9 pb-7 pb-xl-9 d-flex flex-column justify-content-center align-items-center">
                        <img class="col-6" src="/assets/img/storyset/Oops! 404 Error with a broken robot-cuate.svg">
                        <h1 class="text-white fw-light text-center">La page que vous cherchez n'existe pas</h1>
                        <router-link class="col-12 col-lg-6 btn btn-outline-light border-2 rounded-pill btn-lg mt-4 fs-0 py-2" to="/">Retourner a l'accueil<span class="fas fa-play ms-2" data-fa-transform="shrink-6 down-1"></span></router-link>
                    </div>
                </div>
            </div>
            <!-- end of .container-->
        </section>
        <!-- <section> close ============================-->
        <!-- ============================================-->

        <Footer></Footer>
        <div id="scriptHolder"></div>
	</main>
</template>

<script>
	import NavBar from "@/components/NavBar.vue"
	import Footer from "@/components/Footer.vue"

    import loadScripts from "@/scripts/loadScripts.js"

	export default {
		name: "Error404",
		components: {
			NavBar,
            Footer,
		},
        mounted:function() {
            let scriptHolder = document.querySelector('#scriptHolder')
            loadScripts(scriptHolder)
        },
	}
</scriptz>
