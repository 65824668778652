<template>
	<!-- ============================================-->
	<!-- <section> begin ============================-->
	<section class="text-center">
		<div class="container">
			<div class="mt-3 d-flex flex-row justify-content-center align-items-start" style="min-height: 90vh">
				<!-- ACCES REFUSE -->
				<div class="col-lg-6 mt-6 mt-lg-0" v-if="!is_admin">
					<div class="card card-span bg-light h-100">
						<div class="card-body pt-6 pb-4">
							<h1>Accés refusé</h1>
							<button class="btn btn-outline-danger btn-block mt-4" @click="logout">Se deconnecter</button>
						</div>
					</div>
				</div>

				<!-- ACCES AUTORISE -->
				<div class="col-lg-12 mt-6 mt-lg-0" v-else>
					<div class="col-12 d-flex flex-row justify-content-end align-items-start">
						<button class="mx-2 btn btn-outline-primary btn-block" @click="show_hidden = !show_hidden" v-if="!show_hidden">Afficher les messages lus</button>
						<button class="mx-2 btn btn-outline-secondary btn-block" @click="show_hidden = !show_hidden" v-else>Cacher les messages lus</button>
						<button class="mx-2 btn btn-outline-danger btn-block" @click="logout">Se deconnecter</button>
					</div>
					<div class="card card-span bg-light h-100 mt-3">
						<div class="card-head py-4">
							<h2 v-text="'Mes messages ('+ messages.length +')'" v-if="show_hidden"></h2>
							<h2 v-text="'Mes messages ('+ messages.filter(msg => !msg.data().hidden).length +')'" v-else></h2>
						</div>
						<div class="card-body py-4" v-for="msg in messages" v-if="show_hidden || !msg.data().hidden">
							<div class="col-lg-12 mt-4 mt-lg-0">
								<div class="card card-span h-100">
									<div class="card-span-img text-primary" v-if="!msg.data().hidden"><b v-text="msg.data().last_name[0].toUpperCase() + ' ' + msg.data().first_name[0].toUpperCase()"></b></div>
									<div class="card-span-img text-secondary" v-else><b v-text="msg.data().last_name[0].toUpperCase() + ' ' + msg.data().first_name[0].toUpperCase()"></b></div>
									<div class="card-body pt-6 pb-4">
										<div class="row">
											<span class="col-lg-3">
												<p v-text="new Date(msg.data().date * 1000).toLocaleString()"></p>
												<h5 v-text="msg.data().last_name + ' ' + msg.data().first_name"></h5>
												<p v-text="msg.data().email"></p>
											</span>
											<p class="col-lg-9 text-justify" v-text="msg.data().message">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Esse quaerat corporis iste vel dolorem. Aliquid quo tempora praesentium, eius dolores similique perferendis odit cumque odio quod rem vitae natus officiis. Lorem ipsum, dolor sit amet consectetur adipisicing elit. Esse quaerat corporis iste vel dolorem. Aliquid quo tempora praesentium, eius dolores similique perferendis odit cumque odio quod rem vitae natus officiis.</p>
										</div>

										<hr/>

										<button class="mx-3 btn btn-outline-primary btn-block" v-if="!msg.data().hidden" @click="hideMessage(msg)"><i class="far fa-eye-slash"></i></button>
										<button class="mx-3 btn btn-outline-secondary btn-block" v-else @click="unHideMessage(msg)""><i class="far fa-eye"></i></button>
										<button class="mx-3 btn btn-outline-danger btn-block" @click="deleteMessage(msg)"><i class="far fa-trash-alt"></i></button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- end of .container-->
	</section>
	<!-- <section> close ============================-->
	<!-- ============================================-->
</template>

<script>
	import { getFirestore, where, onSnapshot, query, orderBy, collection, deleteDoc, updateDoc } from "firebase/firestore"

	import { getAuth } from "firebase/auth"

	export default {
		name: "AdminPanel",
		props: ["user"],
		data() {
			return {
				is_admin: false,
				unsubscribe: [],
                show_hidden: false,
                messages: [],
			}
		},
		mounted: function () {
			this.getAdminStatus()
            this.getMessages()
		},
		destroyed() {
			this.unsubscribe.forEach((unsubscribe) => unsubscribe())
		},
		methods: {
			getAdminStatus() {
				let tmp_is_admin = false
				if (this.user) {
					this.unsubscribe.push(
						onSnapshot(query(collection(getFirestore(), "admin_users"), where("user_id", "==", this.user.uid) ), (docs) => {
							if (docs.size > 0) {
								let firstTime = true
								docs.forEach((document) => {
									if (firstTime) {
										if (document.data().is_admin) {
											tmp_is_admin = true
										} else {
											tmp_is_admin = false
										}

										firstTime = false
									} else {
										deleteDoc(document.ref)
									}
								})
								this.is_admin = tmp_is_admin
							}
						})
					)
				} else {
					this.is_admin = false
				}
			},

            getMessages() {
                this.unsubscribe.push(
                    onSnapshot(query(collection(getFirestore(), "messages"), orderBy("date","desc")), (docs) => {
                        this.messages = []
                        docs.forEach((document) => {
                            this.messages.push(document)
                        })
                    })
                )
            },

            deleteMessage(msg) {
                Swal.fire({
                    title: 'Voulez vous vraiment supprimer ce message?',
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonText: 'Oui',
                    cancelButtonText: 'Non',
                    confirmButtonColor: '#4b68af',
                    cancelButtonColor: '#784997',
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        deleteDoc(msg.ref)
                    }
                })
            },

            hideMessage(msg) {
                updateDoc(msg.ref, {hidden: true})
            },

            unHideMessage(msg) {
                updateDoc(msg.ref, {hidden: false})
            },

			logout() {
				getAuth().signOut()
			},
		},
	}
</script>
