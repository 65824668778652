<template>
	<main class="main" id="top">
		<NavBar></NavBar>
		<!-- ============================================-->
		<!-- <section> begin ============================-->
		<section class="pb-4">
			<div class="container">
				<div class="row justify-content-center text-center">
					<div class="col-lg-8 col-xl-7 col-xxl-6">
						<h1 class="fs-2 fs-sm-4 fs-md-5 m-0">Nos accompagnements</h1>
					</div>
				</div>
			</div>
			<!-- end of .container-->
		</section>
		<!-- <section> close ============================-->
		<!-- ============================================-->
        
        <!-- ============================================-->
		<!-- <section> begin ============================-->
		<section class="bg p-4">
			<div class="container">
				<div class="row flex-center mt-4">
					<div class="col-md col-lg-6 col-xl-5 ps-lg-6"><img class="img-fluid px-6 px-md-0 pb-5" src="/assets/img/storyset/Thinking face-rafiki.svg" alt="" /></div>
					<div class="col-md col-lg-6 col-xl-5">

                        <h3 class="mb-4">Pourquoi un coaching ?</h3>
						<p>
                            Pour atteindre un objectif.
                        </p>
						<p>
                            Pour optimiser ses capacités.
                        </p>
						<p>
                            Pour acquérir de nouvelles compétences.
                        </p>
						<p>
                            Un coaching peut être utile quand votre enfant :
                            <ul>
                                <li>ressent un mal être.</li>
                                <li>s’isole.</li>
                                <li>voit ses notes chuter.</li>
                                <li>est victime de harcèlement.</li>
                            </ul>
                        </p>
					</div>
				</div>
			</div>
			<!-- end of .container-->
		</section>
		<!-- <section> close ============================-->
		<!-- ============================================-->
        
        <!-- ============================================-->
		<!-- <section> begin ============================-->
		<section class="bg-light p-4">
			<div class="container">
				<div class="row flex-center mt-4">
					<div class="col-md col-lg-6 col-xl-5">

                        <h3 class="mb-4">Comment se déroule un coaching ?</h3>
						<p>
                            Dans la mesure où le coaching est souvent à l’initiative des parents, ils assistent au premier rendez-vous avec leur enfant.
                        </p>
						<p>
                            Il sert à recueillir la demande, définir un objectif et préciser les modalités de l’accompagnement.
                        </p>
						<p>
                            Ensuite, les entretiens se déroulent entre l’adolescent et le coach uniquement. 
                        </p>
						<p>
                            Grâce à de nombreux outils et méthodes d’apprentissage spécifiques, le jeune retrouve confiance en lui et trouve des solutions à l'objectif qu’il s’est fixé lui-même avec l’aide du coach.
                        </p>
						<p>
                            Enfin, à la fin du coaching, une séance de restitution est utile pour que l’adolescent partage ses avancées, son projet et ses ambitions.
                        </p>
					</div>
                    <div class="col-md col-lg-6 col-xl-5 ps-lg-6"><img class="img-fluid px-6 px-md-0 pb-5" src="/assets/img/storyset/Education-pana.svg" alt="" /></div>
				</div>
			</div>
			<!-- end of .container-->
		</section>
		<!-- <section> close ============================-->
		<!-- ============================================-->
        
        <!-- ============================================-->
		<!-- <section> begin ============================-->
		<section class="bg p-4">
			<div class="container">
				<div class="row flex-center mt-4">
					<div class="col-md col-lg-6 col-xl-5 ps-lg-6"><img class="img-fluid px-6 px-md-0 pb-5" src="/assets/img/storyset/Business decisions-amico.svg" alt="" /></div>
					<div class="col-md col-lg-6 col-xl-5">

                        <h3 class="mb-4">Plusieurs types d’accompagnements sont possibles en fonction des besoins.</h3>
						<p>
                            Des rendez-vous individuels avec un nombre de séances prédéfini. <br>
                            Des rendez-vous individuels avec des webinaires collectifs.
                        </p>
						<p>
                            Ce ne sont que quelques propositions. C’est lors de notre séance diagnostique que nous élaborerons votre plan personnalisé.
                        </p>
                        <span class="row">
                            <p class="col-xl-6 col-lg">
                                <u>Ateliers de groupes en webinaire&nbsp;:</u><br>
                                A la découverte de mon cerveau. <br>
                                Les stratégies d’apprentissages. <br>
                                L’erreur. <br>
                                Le sommeil et la nourriture. <br>
                                Les émotions. <br>
                                Les croyances. <br>
                                La confiance en soi. <br>
                                L’aisance à l’oral… <br>
                            </p>
                            <p class="col-xl-6 col-lg">
                                <u>Accompagnement parental&nbsp;:</u><br>
                                Nous vous accompagnons également pour aider votre enfant à découvrir tout son potentiel, à mieux communiquer avec lui et éviter les portes qui claquent, les cris, les crises. <br>
                                Nous pouvons aussi vous apporter une aide pour comprendre l’institution scolaire et dialoguer avec elle. <br>
                            </p>
                        </span>
                        
					</div>
				</div>
			</div>
			<!-- end of .container-->
		</section>
		<!-- <section> close ============================-->
		<!-- ============================================-->

		<Footer></Footer>
        <div id="scriptHolder"></div>
	</main>
</template>

<script>

	import NavBar from "@/components/NavBar.vue"
	import Footer from "@/components/Footer.vue"

    import loadScripts from "@/scripts/loadScripts.js"

	export default {
		name: "Offers",
		components: {
			NavBar,
            Footer,
		},
        mounted:function() {
            let scriptHolder = document.querySelector('#scriptHolder')
            loadScripts(scriptHolder)
        },
	}
</script>
