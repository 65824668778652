<template>
    <!-- ============================================-->
		<!-- <section> begin ============================-->
		<section class="py-0 bg-light">
			<div>
				<hr class="my-0 text-600 opacity-25" />
				<div class="container py-3">
					<div class="row justify-content-between fs--1">
						<div class="col-12 col-sm-auto text-center">
							<p class="mb-0 text-600 opacity-85">2021 &copy; My-learning-coach</p>
						</div>
						<div class="col-12 col-sm-auto text-center">
							<router-link to="/Admin" class="mb-0 text-600 opacity-85">Admin panel</router-link>
						</div>
					</div>
				</div>
			</div>
			<!-- end of .container-->
		</section>
		<!-- <section> close ============================-->
		<!-- ============================================-->
</template>

<script>
export default {
    name: 'Footer'
}
</script>
