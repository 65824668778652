<template>
	<main class="main" id="top">
		<NavBar></NavBar>
		<!-- ============================================-->
		<!-- <section> begin ============================-->
		<section>
			<div class="container">
				<div class="row justify-content-center text-center">
					<div class="col-lg-8 col-xl-7 col-xxl-6">
						<h1 class="fs-2 fs-sm-4 fs-md-5">Qui Sommes nous ?</h1>
					</div>
				</div>
				<div class="row flex-center mt-4">
					<div class="col-md col-lg-6 col-xl-5 ps-lg-6"><img class="img-fluid px-6 px-md-0 pb-5" src="/assets/img/storyset/Filing system-pana.svg" alt="" /></div>
					<div class="col-md col-lg-6 col-xl-5">

						<p>
                            - Enseignante durant 25 ans dans l’éducation nationale
                            <br>
                            - Conseillère pédagogique pour les enseignants du premier degré
                            <br>
                            - Directrice de plusieurs établissements scolaires du premier et du second degré
                        </p>
                        <p>
                            J’ai pu observer et analyser les difficultés que rencontrent les élèves que ce soit dans le domaine des apprentissages, de la motivation, la confiance en soi et les relations humaines.
                        </p>
                        <p>
                            Dans le système éducatif actuel, c’est malheureusement à l’élève de s’adapter.
                        </p>
                        <p>
                            Ma formation initiale scientifique m’a convaincu que c’est en comprenant les mécanismes de l’apprentissage, de la pensée, du traitement de l’information et ceux du cerveau que nous pouvons l’apprivoiser et le maîtriser.
                        </p>
                        <p>
                            Les neurosciences nous permettent aujourd’hui de déterminer comment une fonction cognitive précise est réalisée dans le cerveau.
                        </p>
                        <p>
                            Pour moi, une bonne connaissance en neuroscience est la base essentielle permettant de prendre conscience des fonctionnements et dysfonctionnements de notre pensée en vue d’agir en conséquence.
                        </p>
                        <p>
                            Je me suis donc formée auprès des meilleurs dans ce domaine.
                        </p>
                        <p>
                            Je suis certifiée coach personnel et professionnel spécialisé en neurosciences appliquées auprès de l’<a class="text-dark" href="https://institut-des-neurosciences.com/" target="_blank">INA</a> (institut des neurosciences appliquées)
                        </p>
                        <p>
                            Je suis également titulaire d’une certification de niveau 2 en discipline positive.
                        </p>
                        <p class="text-center mt-4" style="font-weight:100;">
                            <i>
                                Je ne crois pas à la perfection,
                                <br>
                                mais à la motivation de ceux qui cherchent à l’atteindre.
                            </i>
                        </p>
					</div>
				</div>
			</div>
			<!-- end of .container-->
		</section>
		<!-- <section> close ============================-->
		<!-- ============================================-->

		<Footer></Footer>
        <div id="scriptHolder"></div>
	</main>
</template>

<script>

	import NavBar from "@/components/NavBar.vue"
	import Footer from "@/components/Footer.vue"

    import loadScripts from "@/scripts/loadScripts.js"

	export default {
		name: "About",
		components: {
			NavBar,
            Footer,
		},
        mounted:function() {
            let scriptHolder = document.querySelector('#scriptHolder')
            loadScripts(scriptHolder)
        },
	}
</script>
