<template>
    <nav class="navbar navbar-standard navbar-expand-lg fixed-top navbar-light" data-navbar-darken-on-scroll="data-navbar-darken-on-scroll">
        <div class="container">
            <router-link class="navbar-brand" to="/"><img src="/assets/img/logos/MLC/logo_bg_dark.png" style="height: 3rem;"></router-link>
            <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarStandard" aria-controls="navbarStandard" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
            <div class="collapse navbar-collapse scrollbar" id="navbarStandard">
                <ul class="navbar-nav" data-top-nav-dropdowns="data-top-nav-dropdowns">
                    
                </ul>
                <ul class="navbar-nav ms-auto">
                    <li class="nav-item">
                        <router-link class="nav-link px-4" to="/">Accueil</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link px-4" to="/About">Qui sommes nous ?</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link px-4" to="/Offers">Nos accompagnements</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link px-4" to="/Contact">Nous contacter</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'NavBar'
}
</script>
