let loadScripts = function(scriptHolder) {

    let scripts = [
        "/vendors/popper/popper.min.js",
        "/vendors/bootstrap/bootstrap.min.js",
        "/vendors/anchorjs/anchor.min.js",
        "/vendors/is/is.min.js",
        "/vendors/swiper/swiper-bundle.min.js",
        "/vendors/typed.js/typed.js",
        "/vendors/fontawesome/all.min.js",
        "/vendors/lodash/lodash.min.js",
        "https://polyfill.io/v3/polyfill.min.js?features=window.scroll",
        "/vendors/list.js/list.min.js",
        "/assets/js/theme.js"
    ]

    for(let script of scripts){
        let scriptTag = document.createElement('script')
        scriptTag.setAttribute('src', script)
        scriptHolder.appendChild(scriptTag)
    }
}

export default loadScripts