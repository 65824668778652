<template>
	<main class="main" id="top">
		<NavBar></NavBar>
		<!-- ============================================-->
		<!-- <section> begin ============================-->
		<section class="py-0 overflow-hidden light" style="min-height: 85vh" id="banner">
			<div class="bg-holder overlay" style="background-image: url(/assets/img/hero.jpg); background-position: center bottom"></div>
			<!--/.bg-holder-->

			<div class="container">
				<div class="row flex-center pt-8 pt-lg-10 pb-lg-9 pb-xl-0">
					<div class="col-md-11 col-lg-10 col-xl-9 pb-7 pb-xl-9 text-start">
						<h1 class="text-white fw-light">Une nouvelle façon <span class="typed-text fw-bold" data-typed-text='["d’apprendre","de vivre sa scolarité","de prendre confiance en soi","de gérer son stress"]'></span><br /></h1>
						<h2 class="lead text-white opacity-75"><i>" Libérez le potentiel d'un enfant et vous transformerez le monde avec lui ."</i></h2>
						<a href="https://fr.wikipedia.org/wiki/Maria_Montessori" target="_blank" class="d-block text-start text-white opacity-75">Maria Montessori</a>
						<router-link class="btn btn-outline-light border-2 rounded-pill btn-lg mt-4 fs-0 py-2" to="/Offers">Découvrir nos accompagnements<span class="fas fa-play ms-2" data-fa-transform="shrink-6 down-1"></span></router-link>
					</div>
				</div>
			</div>
			<!-- end of .container-->
		</section>
		<!-- <section> close ============================-->
		<!-- ============================================-->

		<!-- ============================================-->
		<!-- <section> begin ============================-->
		<section class="bg-light text-center">
			<div class="container">
				<div class="row">
					<div class="col">
						<h1 class="fs-2 fs-sm-4 fs-md-5">Notre philosophie</h1>
						<!-- <p class="lead">Things you will get right out of the box with Falcon.</p> -->
					</div>
				</div>
				<div class="row mt-6">
					<div class="col-lg-3 mt-6 mt-lg-0">
						<div class="card card-span h-100">
							<div class="card-span-img text-secondary"><span class="fs-1 fas fa-graduation-cap"></span></div>
							<div class="card-body pt-6 pb-4">
								<h5 class="mb-2">Mieux vivre sa scolarité</h5>
								<p style="font-size: 1rem; font-weight: 100">Parceque un enfant qui se sent mieux est un enfant qui apprend mieux.</p>
							</div>
						</div>
					</div>
					<div class="col-lg-3 mt-6 mt-lg-0">
						<div class="card card-span h-100">
							<div class="card-span-img text-secondary"><span class="fs-1 fas fa-brain"></span></div>
							<div class="card-body pt-6 pb-4">
								<h5 class="mb-2">Améliorer sa façon d’apprendre</h5>
								<p style="font-size: 1rem; font-weight: 100">Pour apprendre quoi que ce soit, commence par lui donner du sens.</p>
							</div>
						</div>
					</div>
					<div class="col-lg-3 mt-6 mt-lg-0">
						<div class="card card-span h-100">
							<div class="card-span-img text-secondary"><span class="fs-1 fas fa-heart-broken"></span></div>
							<div class="card-body pt-6 pb-4">
								<h5 class="mb-2">Développer la confiance en soi</h5>
								<p style="font-size: 1rem; font-weight: 100">Sois toi-même, toutes les autres personnalités sont déjà prises.</p>
							</div>
						</div>
					</div>
					<div class="col-lg-3 mt-6 mt-lg-0">
						<div class="card card-span h-100">
							<div class="card-span-img text-secondary"><span class="fs-1 fas fa-users"></span></div>
							<div class="card-body pt-6 pb-4">
								<h5 class="mb-2">Apprendre a gérer son stress</h5>
								<p style="font-size: 1rem; font-weight: 100">Je ne perds jamais, soit je réussis, soit j'apprends.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- end of .container-->
		</section>
		<!-- <section> close ============================-->
		<!-- ============================================-->

		<!-- ============================================-->
		<!-- <section> begin ============================-->
		<section>
			<div class="container">
				<div class="row justify-content-center text-center">
					<div class="col-lg-8 col-xl-7 col-xxl-6">
						<h1 class="fs-2 fs-sm-4 fs-md-5">Notre Coaching</h1>
					</div>
				</div>
				<div class="row flex-center mt-4">
					<div class="col-md col-lg-6 col-xl-5 ps-lg-6"><img class="img-fluid px-6 px-md-0" src="/assets/img/storyset/Webinar-amico.svg" alt="" /></div>
					<div class="col-md col-lg-6 col-xl-5">

						<h3 class="py-3">Qui vous accompagne ?</h3>
						<p>
                            Vous serez accompagnés, tout au long de votre parcours, par un coach professionnel spécialisé en neurosciences. <br>
                            Le coach qui vous suivra sera toujours le même et deviendra votre référent.
                        </p>

						<h3 class="mt-5 py-3">Un accompagnement complet <br> et personnalisé</h3>
                        <p class="text-center fs-1" style="font-weight:100;"><i>Nous conjuguons le savoir au futur et au pluriel.</i></p>
						<p>
                            Pour nous, il n’y a pas un seul type d’apprenant, une seule manière d’apprendre. 
                            Nos modèles sont multiples et de tous les horizons, parce que chacun est unique et doit s’approprier son savoir, faire les choses à sa façon.
                        </p>
                        <p>
                            Lors de la séance diagnostique, nous définirons ensemble vos objectifs, vos difficultés, vos appréhensions, vos besoins, vos talents …
                            Nous vous proposerons alors un plan d’action individuel basé sur vos besoins.
                        </p>
					</div>
				</div>
			</div>
			<!-- end of .container-->
		</section>
		<!-- <section> close ============================-->
		<!-- ============================================-->

		<!-- ============================================-->
		<!-- <section> begin ============================-->
		<section class="bg-light text-center">
			<div class="container">
				<div class="row">
					<div class="col">
						<h1 class="fs-2 fs-sm-4 fs-md-5">Nos offres</h1>
						<!-- <p class="lead">Things you will get right out of the box with Falcon.</p> -->
					</div>
				</div>
				<div class="row mt-6">
					<div class="col-lg-4 mt-6 mt-lg-0">
						<div class="card card-span h-100">
							<div class="card-header">
								<h5 class="mb-2">Formule "Junior"</h5>
							</div>
							<div class="card-body pt-3 pb-4">
								<img class="px-5" src="/assets/img/storyset/Back to school-bro.svg" />
                                <p class="text-start">
                                    <strong>Pour les moins de 13 ans</strong>
                                </p>
								<ul class="text-start" style="list-style: '- '">
									<li>Accompagnement parent-enfant</li>
									<li>Pour une meilleure scolarité</li>
									<li>Méthotodologie des apprentissages</li>
									<li>Diagnostique et plan personnalisé</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="col-lg-4 mt-6 mt-lg-0">
						<div class="card card-span h-100">
							<div class="card-header">
								<h5 class="mb-2">Formule "Essentielle"</h5>
							</div>
							<div class="card-body pt-3 pb-4">
								<img class="px-5" src="/assets/img/storyset/Learning-amico.svg" />
                                <p class="text-start">
                                    <strong>A partir de 13 ans</strong>
                                </p>
								<ul class="text-start" style="list-style: '- '">
									<li>Pour une meilleure scolarité</li>
									<li>Pour prendre confiance en soi</li>
									<li>
										Suivi personnalisé
										<ul style="list-style: '. '">
											<li>Identification des obstacles</li>
											<li>Méthotodologie des apprentissages</li>
											<li>Solutions adaptées</li>
										</ul>
									</li>
									<li>Diagnostique et plan personnalisé</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="col-lg-4 mt-6 mt-lg-0">
						<div class="card card-span h-100">
							<div class="card-header">
								<h5 class="mb-2">Formule "Examens"</h5>
							</div>
							<div class="card-body pt-3 pb-4">
								<img class="px-5" src="/assets/img/storyset/Exams-bro.svg" />
                                <p class="text-start">
                                    <strong>A partir de 13 ans</strong>
                                </p>
								<ul class="text-start" style="list-style: '- '">
									<li>Pour mieux vivre ses examens</li>
									<li>Pour gérer son stress</li>
									<li>Pour plus de confiance en soi</li>
									<li>Pour une aisance à l’oral</li>
									<li>
										Suivi personnalisé
										<ul style="list-style: '. '">
											<li>Identification des obstacles</li>
											<li>Méthotodologie et outils</li>
											<li>Solutions adaptées</li>
										</ul>
									</li>
									<li>Diagnostique et plan personnalisé</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="row flex-center">
					<div class="col-12 mt-6">
						<div class="card card-span h-100">
							<div class="card-body p-5 text-start">
								<p style="font-size: 1.5rem; font-weight: 100">Tous nos accompagnements sont disponibles en présentiel (Amiens et ses environs) et en distanciel</p>
								<p style="font-size: 1.5rem; font-weight: 100">Scéance de coaching à partir de 70€</p>
								<p style="font-size: 1.5rem; font-weight: 100">Scéance de diagnostique préliminaire à partir de 50€</p>
								<div class="px-1 d-flex flex-row justify-content-center flex-wrap">
									<router-link class="nav-link px-4" to="/Contact">
                                        <button class="btn btn-outline-primary border-2 btn-lg mt-4 fs-0 py-2 mx-5">Nous contacter</button>
                                    </router-link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- end of .container-->
		</section>
		<!-- <section> close ============================-->
		<!-- ============================================-->

		<!-- ============================================-->
		<!-- <section> begin ============================-->
		<section>
			<div class="container">
				<div class="row flex-center mt-4">
					<div class="col-12 col-md-3"><img class="img-fluid px-6 px-md-0" src="/assets/img/storyset/Video tutorial-amico.svg" alt="" /></div>
					<div class="col-12 col-md-9">
						<h3>Bientôt disponible : nos formations et webinaires en ligne.</h3>
					</div>
				</div>
			</div>
			<!-- end of .container-->
		</section>
		<!-- <section> close ============================-->
		<!-- ============================================-->

		<Footer></Footer>
        
        <div id="scriptHolder"></div>
	</main>
</template>

<script>
    

	import NavBar from "@/components/NavBar.vue"
	import Footer from "@/components/Footer.vue"

    import loadScripts from "@/scripts/loadScripts.js"

	export default {
		name: "Home",
		components: {
			NavBar,
            Footer,
		},
        mounted:function() {
            let scriptHolder = document.querySelector('#scriptHolder')
            loadScripts(scriptHolder)
        },
	}
</script>