<template>
    <div>
        <slot name="user" :user="user"></slot>
    </div>
</template>

<script>
import{getAuth} from 'firebase/auth'

import {ref} from '@vue/composition-api'

export default {
    name: "User",
    setup() {
        const user = ref(null)

        const unsubscribe = []

        let tmp_unsub = getAuth().onAuthStateChanged((firebaseUser) => {
            user.value = firebaseUser
        })

        unsubscribe.push(tmp_unsub)

        return{
            user,
            unsubscribe
        }
    },
    destroyed() {
        for(let unsub of this.unsubscribe) {
            unsub()
        }
    }
}
</script>