import { initializeApp } from "firebase/app"

//import { getAnalytics } from "firebase/analytics"

const firebaseConfig = {
    apiKey: "AIzaSyAiZKY0n5FxpEvEbktVAoQEhP82BDG7iRA",
    authDomain: "my-learning-coach.firebaseapp.com",
    projectId: "my-learning-coach",
    storageBucket: "my-learning-coach.appspot.com",
    messagingSenderId: "180430494448",
    appId: "1:180430494448:web:61de919be16a9d16170035"
};

const app = initializeApp(firebaseConfig)

//getAnalytics()

export default app