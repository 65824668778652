<template>
	<main class="main" id="top">
		<NavBar></NavBar>

		<!-- ============================================-->
		<!-- <section> begin ============================-->
		<section class="text-center">
			<div class="container">
				<div class="row">
					<div class="col">
						<h1 class="fs-2 fs-sm-4 fs-md-5">Nous contacter</h1>
						<!-- <p class="lead">Things you will get right out of the box with Falcon.</p> -->
					</div>
				</div>
				<div class="row mt-6">
					<div class="col-lg-6 mt-6 mt-lg-0">
						<div class="card card-span bg-light h-100">
							<div class="card-span-img text-secondary"><span class="fs-1 fas fa-question"></span></div>
							<div class="card-body pt-6 pb-4">
								<h5 class="mb-4">Une question</h5>
								<form @submit.prevent="sendMessage">
                                        <input type="text" required class="form-control bg-light border-secondary text-dark my-3" style="border-left:none;border-right:none;border-top:none;border-radius:0;box-shadow: none;" placeholder="Nom" v-model="last_name">
                                        <input type="text" required class="form-control bg-light border-secondary text-dark my-3" style="border-left:none;border-right:none;border-top:none;border-radius:0;box-shadow: none;" placeholder="Prenom" v-model="first_name">
                                        <input type="email" required class="form-control bg-light border-secondary text-dark my-3" style="border-left:none;border-right:none;border-top:none;border-radius:0;box-shadow: none;" placeholder="Email" v-model="email">
                                        <textarea required minlength="10" maxlength="2048" class="form-control bg-light border-secondary text-dark my-3" style="border-left:none;border-right:none;border-top:none;border-radius:0;box-shadow: none;" placeholder="Votre message" rows="6" v-model="message"></textarea>
                                        <button type="submit" class="btn btn-outline-secondary btn-block mt-4">Envoyer mon message</button>
                                </form>
							</div>
						</div>
					</div>
					<div class="col-lg-6 mt-6 mt-lg-0">
						<div class="card card-span bg-light h-100">
							<div class="card-span-img text-primary"><span class="fs-1 fas fa-users"></span></div>
							<div class="card-body pt-6 pb-4">
								<h5 class="mb-4">Prendre rendez vous</h5>
                                <p class="text-justify" style="font-size:.8rem;font-weight:100;">
                                    <i>* Si vous avez déjà effectué une scéance de diagnostique avec notre équipe merci de prendre contact avec votre référent afin de planifier vos scéances d'accompagnement</i>
                                </p>
                                <div class=" mt-5 d-flex flex-row align-items-center justify-content-between">
                                    <input class="m-3" type="checkbox" name="validation" id="validation" v-model="rdv_accepted">
                                    <label class="m-0 text-justify" style="font-size:.8rem;font-weight:100 !important;" for="validation">J'ai pris connaissance des règles de prise de rendez vous et m'engage à respecter ces dernières.</label>
                                </div>
								<button class="btn btn-outline-primary btn-block mt-4" :disabled="!rdv_accepted" @click="rdv">Prendre rendez vous </button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- end of .container-->
		</section>
		<!-- <section> close ============================-->
		<!-- ============================================-->

		<Footer></Footer>
        <div id="scriptHolder"></div>
	</main>
</template>

<script>
    import {getFirestore, collection, addDoc} from 'firebase/firestore'

	import NavBar from "@/components/NavBar.vue"
	import Footer from "@/components/Footer.vue"

    import loadScripts from "@/scripts/loadScripts.js"

	export default {
		name: "Contact",
		components: {
			NavBar,
            Footer,
		},
        data() {
            return {
                rdv_accepted: false,
                first_name: "",
                last_name: "",
                email: "",
                message: "",
            }
        },
        mounted:function() {
            let scriptHolder = document.querySelector('#scriptHolder')
            loadScripts(scriptHolder)
        },
        methods: {
            sendMessage(){
                addDoc(collection(getFirestore(), "messages"),{
                    date : new Date(),
                    first_name: this.first_name,
                    last_name: this.last_name,
                    email: this.email,
                    message: this.message,
                    hidden: false,
                })
                .then(doc => {
                    Swal.fire('Votre message a bien été envoyé !')
                    this.first_name = ""
                    this.last_name = ""
                    this.email = ""
                    this.message = ""
                })
            },
            rdv(){
                window.open("https://calendly.com/my-learning-coach/diagnostique", "_blank")
            }
        },
	}
</script>
