import Vue from "vue"
import VueRouter from "vue-router"

import Home from "../views/Home.vue"
import About from "../views/About.vue"
import Offers from "../views/Offers.vue"
import Contact from "../views/Contact.vue"
import Admin from "../views/Admin.vue"

import Error404 from "../views/Error404.vue"

Vue.use(VueRouter)

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
	},
	{
		path: "/About",
		name: "About",
		component: About,
	},
	{
		path: "/Offers",
		name: "Offers",
		component: Offers,
	},
	{
		path: "/Contact",
		name: "Contact",
		component: Contact,
	},
	{
		path: "/Admin",
		name: "Admin",
		component: Admin,
	},

    //404
	{
		path: "*",
		name: "Error404",
		component: Error404,
	},
]

const router = new VueRouter({
	routes,
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
})

export default router
