<template>
	<main class="main" id="top">
		<NavBar></NavBar>
		<User v-slot:user="{ user }">
			<div v-if="user">
                <AdminPanel :user="user"></AdminPanel>
            </div>
			<div v-else>
				<!-- ============================================-->
				<!-- <section> begin ============================-->
				<section class="text-center">
					<div class="container">
						<div class="mt-6 d-flex flex-row justify-content-center align-items-start" style="min-height: 90vh">
							<div class="col-lg-6 mt-6 mt-lg-0">
								<div class="card card-span bg-light h-100">
									<div class="card-span-img text-primary"><span class="fs-1 fas fa-lock"></span></div>
									<div class="card-body pt-6 pb-4">
										<button class="btn btn-outline-primary btn-block mt-4" @click="loginWithGoogle">Se connecter</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- end of .container-->
				</section>
				<!-- <section> close ============================-->
				<!-- ============================================-->
			</div>
		</User>

		<Footer></Footer>

		<div id="scriptHolder"></div>
	</main>
</template>

<script>
	import NavBar from "@/components/NavBar.vue"
	import Footer from "@/components/Footer.vue"
	import User from "@/components/utility/User.vue"
    import AdminPanel from "@/components/AdminPanel.vue"

	import loadScripts from "@/scripts/loadScripts.js"

	import { getAuth, signInWithRedirect , GoogleAuthProvider } from "firebase/auth"

	export default {
		name: "Admin",
		components: {
			User,
			NavBar,
			Footer,
            AdminPanel,
		},
		data() {
			return {
				googleProvider: new GoogleAuthProvider(),
			}
		},
        created: function() {
            this.googleProvider.setCustomParameters({
                prompt: 'select_account'
            });
        },
		mounted: function () {
			let scriptHolder = document.querySelector("#scriptHolder")
			loadScripts(scriptHolder)
		},
		methods: {
			loginWithGoogle() {
				signInWithRedirect (getAuth(), this.googleProvider)
			},
			logout() {
				getAuth().signOut()
			},
		},
	}
</script>
